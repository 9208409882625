import { useState, useEffect } from 'react';
import { emailValidation } from '@phillips/seldon/dist/utils';
import getPhillipsBackboneProperty from '../utils/getPhillipsBackboneProperty';
import sendAnalytics from '../../utils/sendAnalytics';


export const useSubscribeToNewsletter = () => {
  const [state, setState] = useState('default');

  const request = async (email, form) => {
    setState('loading');
    try {
      sendAnalytics({
        eventCategory: `Newsletter Sign-Ups`,
        eventAction: 'Newsletter Sign-Ups',
        eventLabel: `Email ${email}`,
        userEmail: email
      });
    } catch (err) {
      console.log("err", err)
    }
    try {
      const response = await fetch('/SaveEmailSignup', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ email })
      });

      if (!response.ok) {
        throw new Error('Subscription failed');
      }

      setState('success');
      form.reset();
    } catch (err) {
      setState('error');
    }
  };

  const subscribe = e => {
    e.preventDefault();
    const { target } = e;

    const nativeData = new FormData(target);
    const data = Object.fromEntries(nativeData.entries());
    const email = data?.email;
    const emailInput = target[0];

    if (email === '') {
      emailInput.focus();
      return;
    }

    if (!emailValidation(email)) {
      setState('invalid');
      emailInput.focus();
      return;
    }

    request(email, target);
    console.log('Form submitted for email -', data?.email);
  };

  return { state, subscribe };
};

export const determineSubscribeToNewsletterTexts = (
  getI18nLabel,
  subscriptionState
) => {
  const title = getI18nLabel({ label: 'subscribeToNewsletter' });

  const blurb = (
    <div>
      <div>{getI18nLabel({ label: 'footerSubscribeDescription' })}</div>
    </div>
  );

  const inputLabelText = getI18nLabel({ label: 'emailLabel' });

  const buttonText =
    subscriptionState === 'success'
      ? getI18nLabel({ label: 'footerSubscribeSuccess' })
      : getI18nLabel({ label: 'submit' });

  const inputPlaceholder = 'example@email.com';

  const loadingString = getI18nLabel({ label: 'footerSubscribeLoading' });
  const validEmailError = getI18nLabel({ label: 'validEmailError' });
  const successText = getI18nLabel({ label: 'signUpThanks' });
  const emailAgreement = getI18nLabel({ label: 'emailAgreement' });

  return {
    title,
    blurb,
    inputLabelText,
    buttonText,
    inputPlaceholder,
    validEmailError,
    loadingString,
    successText,
    emailAgreement
  };
};

export const useActionUpdatesRoute = ({
  dispatch,
  phillipsEvents,
  language,
  location
}) => {
  useEffect(() => {
    const handleUpdate = data => {
      // dispatching action updates route
      dispatch(data);
    };
    getPhillipsBackboneProperty('Events')
      .then(res => {
        // eslint-disable-next-line no-param-reassign
        phillipsEvents.current = res;
        phillipsEvents.current.on('update', handleUpdate);
        if (language !== 'en' && location.type === 'ROUTES_DEFAULT') {
          dispatch({
            type: 'ROUTES_LANGUAGE',
            payload: {
              language
            }
          });
        }
      })
      .catch(err =>
        console.error('There was an error fetching phillips.Events: ', err)
      );
  }, [dispatch, language]);
};
