import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Footer as FooterWrapper,
  Social,
  Link,
  TextVariants,
  Text,
  Accordion,
  AccordionItem,
  Drawer
} from '@phillips/seldon';
import PhillipsModal from '../PhillipsModal/PhillipsModal';
import SubscribeToNewsletter from './SubscribeToNewsletter';
import setI18nLabel, { setI18nLinkLanguage } from '../../utils/getI18nLabel';
import { useActionUpdatesRoute } from './hooks';

const aboutUsLinks = (getI18nLink, getI18nLabel) => (
  <ul>
    <li>
      <a href={getI18nLink('/about')}>
        {getI18nLabel({ label: 'ourHistory' })}
      </a>
    </li>
    <li>
      <a href={getI18nLink('/team')}>{getI18nLabel({ label: 'ourTeam' })}</a>
    </li>
    <li>
      <a href={getI18nLink('/location')}>
        {getI18nLabel({ label: 'locations' })}
      </a>
    </li>
    <li>
      <a href={getI18nLink('/press')}>{getI18nLabel({ label: 'press' })}</a>
    </li>
    <li>
      <a href={getI18nLink('/careers')}>{getI18nLabel({ label: 'careers' })}</a>
    </li>
    <li>
      <a href={getI18nLink('/sitemap')}>{getI18nLabel({ label: 'siteMap' })}</a>
    </li>
  </ul>
);

const ourServicesLinks = (getI18nLink, getI18nLabel) => (
  <ul>
    <li>
      <a href={getI18nLink('/buysell/buy')}>
        {getI18nLabel({ label: 'howToBuy' })}
      </a>
    </li>
    <li>
      <a href={getI18nLink('/buysell/sell')}>
        {getI18nLabel({ label: 'howToSell' })}
      </a>
    </li>
    <li>
      <a href={getI18nLink('/buysell/private')}>
        {getI18nLabel({ label: 'privateServices' })}
      </a>
    </li>
    <li>
      <a href={getI18nLink('/professional-advisor-services')}>
        {getI18nLabel({ label: 'professionalAdvisorServices' })}
      </a>
    </li>
    <li>
      <a href={getI18nLink('/fiduciaryservices')}>
        {getI18nLabel({ label: 'fiduciaryServices' })}
      </a>
    </li>
  </ul>
);

const policyLinks = (getI18nLink, getI18nLabel) => (
  <ul>
    <li>
      <a href={getI18nLink('/about/privacy')}>
        {getI18nLabel({ label: 'privacyPolicy' })}
      </a>
    </li>
    <li>
      <a href={getI18nLink('/about/cookiepolicy')}>
        {getI18nLabel({ label: 'cookiePolicyLabel' })}
      </a>
    </li>
    <li>
      <a href={getI18nLink('/about/modernslaverypolicy')}>
        {getI18nLabel({ label: 'modernSlaveryPolicy' })}
      </a>
    </li>
  </ul>
);

const Footer = () => {
  const phillipsEvents = useRef(null);
  const { language, location } = useSelector(store => store);
  const [openSubscribeDrawer, setOpenSubscribeDrawer] = useState(false);
  const [showWeChatQR, setShowWeChatQr] = useState(false);
  const [showRedQR, setRedQr] = useState(false);

  const getI18nLink = setI18nLinkLanguage(language);

  const dispatch = useDispatch();
  const getI18nLabel = setI18nLabel(language);
  useActionUpdatesRoute({ dispatch, phillipsEvents, language, location });

  const handleWeChatQr = e => {
    e.preventDefault();
    setShowWeChatQr(prev => !prev);
  };

  const handleRedQr = e => {
    e.preventDefault();
    setRedQr(prev => !prev);
  };

  const socialLinks = [
    {
      href: 'https://www.instagram.com/phillipsauction/',
      name: 'insta',
      src: 'footer-instagram-new',
      onClick: () => {}
    },
    {
      href: 'https://www.linkedin.com/company/phillips/',
      name: 'linkedin',
      src: 'footer-linkedin',
      onClick: () => {}
    },
    {
      href: 'https://weixin.qq.com/r/qDuLk0-EKhJVrQvw9266',
      name: 'wechat',
      src: 'footer-wechat-new',
      onClick: handleWeChatQr
    },
    {
      href: 'https://www.xiaohongshu.com/user/profile/611e10a8000000000101ddf7',
      name: 'red',
      src: 'footer-red',
      onClick: handleRedQr
    },
    {
      href: 'https://www.facebook.com/phillipsauction/',
      name: 'facebook',
      src: 'footer-facebook',
      onClick: () => {}
    }
  ];

  return (
    <>
      <FooterWrapper>
        <div className="phillips-footer-desktop">
          <Text
            variant={TextVariants.heading4}
            className="phillips-footer-desktop__header"
          >
            {getI18nLabel({ label: 'aboutUs' })}
          </Text>
          {aboutUsLinks(getI18nLink, getI18nLabel)}
        </div>

        <div className="phillips-footer-desktop">
          <Text
            variant={TextVariants.heading4}
            className="phillips-footer-desktop__header"
          >
            {getI18nLabel({ label: 'ourServices' })}
          </Text>
          {ourServicesLinks(getI18nLink, getI18nLabel)}
        </div>

        <div className="phillips-footer-desktop">
          <Text
            variant={TextVariants.heading4}
            className="phillips-footer-desktop__header"
          >
            {getI18nLabel({ label: 'policies' })}
          </Text>
          {policyLinks(getI18nLink, getI18nLabel)}
        </div>

        <Accordion className="phillips-footer-mobile">
          <AccordionItem
            isLocked={false}
            variation=""
            label={getI18nLabel({ label: 'aboutUs' })}
            key="accordion-key-about-us"
            id="accordion-item-about-us"
          >
            {aboutUsLinks(getI18nLink, getI18nLabel)}
          </AccordionItem>

          <AccordionItem
            isLocked={false}
            variation=""
            label={getI18nLabel({ label: 'ourServices' })}
            key="accordion-key-our-services"
            id="accordion-item-our-services"
          >
            {ourServicesLinks(getI18nLink, getI18nLabel)}
          </AccordionItem>

          <AccordionItem
            isLocked={false}
            variation=""
            label={getI18nLabel({ label: 'policies' })}
            isLastItem
            key="accordion-key-policies"
            id="accordion-item-policies"
          >
            {policyLinks(getI18nLink, getI18nLabel)}
          </AccordionItem>
        </Accordion>

        <Social
          onSubscribeClick={() => setOpenSubscribeDrawer(true)}
          titleText={getI18nLabel({ label: 'subscribeToNewsletter' })}
          buttonText={getI18nLabel({ label: 'subscribeToNewsletterAction' })}
        >
          <ul className="phillips-social-desktop">
            {socialLinks.map(({ href, name, src, onClick }) => (
              <li key={name}>
                <Link
                  href={href}
                  onClick={onClick}
                  target="_blank"
                  rel="noreferrer"
                  variant="list"
                >
                  <img src={`/images/${src}.svg`} alt={name} title={name} />
                </Link>
              </li>
            ))}
          </ul>

          <ul className="phillips-social-mobile">
            {socialLinks.map(({ href, name, src }) => (
              <li key={name}>
                <Link
                  href={href}
                  onClick={() => ({})}
                  target="_blank"
                  rel="noreferrer"
                  variant="list"
                >
                  <img src={`/images/${src}.svg`} alt={name} title={name} />
                </Link>
              </li>
            ))}
          </ul>

          <Drawer
            isOpen={openSubscribeDrawer}
            onClose={() => setOpenSubscribeDrawer(false)}
            id="subscribe-drawer"
          >
            <SubscribeToNewsletter language={language} />
          </Drawer>
        </Social>
      </FooterWrapper>

      {showWeChatQR ? (
        <PhillipsModal
          customClasses={['phillips-modal__qrcode']}
          hideModal={() => setShowWeChatQr(false)}
        >
          <div id="qr-code" className="show">
            <div id="qr-code-image-wechat" />
          </div>
        </PhillipsModal>
      ) : null}

      {showRedQR ? (
        <PhillipsModal
          customClasses={['phillips-modal__qrcode']}
          hideModal={() => setRedQr(false)}
        >
          <div id="qr-code" className="show">
            <div id="qr-code-image-red" />
          </div>
        </PhillipsModal>
      ) : null}
    </>
  );
};

export default Footer;
