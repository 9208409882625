import React from 'react';
import { Provider } from 'react-redux';
import PropTypes from 'prop-types';
import { configureStoreWithRouter } from '../utils/configureStore';
import Footer from './Footer';
import { language } from '../PhillipsI18N/reducers';
import getRoutes from '../PhillipsNav/routes';

const FooterIndex = ({ language: lang, location }) => {
  const { routesMap, options } = getRoutes(location);
  const store = configureStoreWithRouter(
    {
      language
    },
    {
      language: lang
    },
    routesMap,
    options
  );
  return (
    <Provider store={store}>
      <Footer />
    </Provider>
  );
};
// FooterIndex.defaultProps = {
//   language: 'en'
// };
FooterIndex.propTypes = {
  language: PropTypes.string.isRequired,
  location: PropTypes.string.isRequired
};

export { FooterIndex };
