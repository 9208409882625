import React from 'react';
import {
  Subscribe,
  SubscriptionState,
  Text,
  TextVariants
} from '@phillips/seldon';
import PropTypes from 'prop-types';
import setI18nLabel from '../../utils/getI18nLabel';
import {
  useSubscribeToNewsletter,
  determineSubscribeToNewsletterTexts
} from './hooks';

const SubscribeToNewsletter = ({ language }) => {
  const getI18nLabel = setI18nLabel(language);
  const { state: subscriptionState, subscribe } = useSubscribeToNewsletter();
  const text = determineSubscribeToNewsletterTexts(
    getI18nLabel,
    subscriptionState
  );

  if (subscriptionState === SubscriptionState.Success) {
    return (
      <Text variant={TextVariants.heading3}>
        {text.successText}
      </Text>
    );
  }

  return (
    <Subscribe
      title={text.title}
      blurb={text.blurb}
      inputLabelText={text.inputLabelText}
      buttonText={text.buttonText}
      inputPlaceholder={text.inputPlaceholder}
      subscriptionState={subscriptionState}
      invalidText={text.validEmailError}
      successText={text.successText}
      loadingText={text.loadingString}
      privacyText={text.emailAgreement}
      onSubmit={subscribe}
    />
  );
};

SubscribeToNewsletter.propTypes = {
  language: PropTypes.string.isRequired
};

export default SubscribeToNewsletter;
